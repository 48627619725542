import React from 'react';
import { Helmet } from 'react-helmet';

export default ({ children }) => {
	return (
		<Helmet>
			<html lang='ja' />
			{children}
		</Helmet>
	);
};
