import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export default ({ children }) => {
	const data = useStaticQuery(graphql`
		query {
			currentBuildDate {
				currentDate
			}
		}
	`);

	return (
		<>
			<header>
				<a href='/top.html' className='siteName'>
					<img src='/logo.png' alt='ロゴ' style={{ width: '1rem' }} />
					たらこ
				</a>
			</header>
			<main>{children}</main>
			<hr />
			<footer>
				<div>広告を入れる予定です。</div>
				<div style={{ color: 'white' }}>更新日時：{data.currentBuildDate.currentDate}</div>
			</footer>
		</>
	);
};
